<template>
  <VerifyEmployment />
</template>
<script>
import VerifyEmployment from "@/components/VerifyEmployment";
export default {
  components: {
    VerifyEmployment,
  },
};
</script>

<template>
  <Customers />
</template>
<script>
  import Customers from "@/components/Customers/Customer.vue";
  export default {
    components: {
      Customers,
    },
  };
</script>
